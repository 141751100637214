import { store } from "../store";
import { authChange } from "../store/Auth";
import HttpHandler from "./http-handler";
import { HttpRequest as BaseHttpRequest } from "./http-request";

export class HttpRequest extends BaseHttpRequest {
  store = store;
  errorHandler = (statusCode: number, error: HttpHandler): void => {
    if (statusCode === 401) {
      store.dispatch(authChange());

      window.location.href = "https://dev-cp.dehub.mn";
    }

    throw error;
  };
}

function currencyFormat(num: number) {
  return (
    parseFloat(`${num}`)
      ?.toFixed(2)
      ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,") + " ₮"
  );
}

export { currencyFormat };
